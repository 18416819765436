export class Parent {
  constructor(
    public _id: string,
    public name: string,
    public alias: string,
    public image: string,
    public active: string,
    public tipo: string
  ) { }
}
