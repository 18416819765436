import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { global } from "../Config/config";

@Injectable()
export class CodeService {
  public url: string;

  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  getMyCredits(values): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.post(this.url + "get-my-credits/", values, {
      headers: headers,
    });
  }

  checkCodeAvailability(values): Observable<any> {
    let params = values;
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.post(this.url + "check-code-availability/", params, {
      headers: headers,
    });
  }

  sendCode(values): Observable<any> {
    let params = values;
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.post(this.url + "send-code/", params, {
      headers: headers,
    });
  }

  sendCodeType(values): Observable<any> {
    let params = values;
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.post(this.url + "send-code-type/", params, {
      headers: headers,
    });
  }

  getCodesOnPrepaid(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

    // Hacer peticion ajax
    return this._http.get(this.url + "get-codes-prepaid-order/" + id, { headers: headers });
  }

  //For master functionalities
  sendCodeByManualProcess(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.post(this.url + "send-code-by-manual-process/", params, {
      headers: headers,
    });
  }

  //For master functionalities
  updateCodeAvailability(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.get(this.url + "update-code-availability/" + id, {
      headers: headers,
    });
  }


  resendCodeByManualProcess(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.post(this.url + "resend-code-by-manual-mode/", params, {
      headers: headers,
    });
  }

  activateSmartTaskCodeSync(): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

    // Hacer peticion ajax
    return this._http.get(this.url + "sync-codes/", { headers: headers });
  }

  getBalanceOfCodesInWarehouse(): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

    // Hacer peticion ajax
    return this._http.get(this.url + "available-codes", { headers: headers });
  }

  getAllTheCodes(): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

    // Hacer peticion ajax
    return this._http.get(this.url + "get-all-the-codes/", { headers: headers });
  }
  saveCodes(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

    // Hacer peticion ajax
    return this._http.post(this.url + "save-codes/", params, { headers: headers });
  }

  readCodesFromTheExcel(): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

    // Hacer peticion ajax
    return this._http.get(this.url + "read-codes", { headers: headers });
  }

  getUsersListForCodesDelivery(): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

    // Hacer peticion ajax
    return this._http.get(this.url + "get-users-list-code-delivery", { headers: headers });
  }

  sendCodesInBulk(params, type): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

    // Hacer peticion ajax
    return this._http.post(this.url + "send-codes-in-bulk/" + type, params, { headers: headers });
  }

}
