import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from "../Config/config";

@Injectable()
export class CampusService {
  public url: string;

  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  getCampus(val): Observable<any> {

    let id = val;
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    // Hacer peticion ajax
    return this._http.get(this.url + 'get-campus/' + id, { headers: headers });
  }

  getAllTheCampusesInThisShop(val): Observable<any> {

    let id = val;
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    // Hacer peticion ajax
    return this._http.get(this.url + 'get-campuses/' + id, { headers: headers });
  }

  createCampus(params): Observable<any> {


    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    // Hacer peticion ajax
    return this._http.post(this.url + 'create-campus/', params, { headers: headers });
  }

}