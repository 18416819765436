import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'

import { global } from '../Config/config'

@Injectable()
export class ComissionService {
  public url: string

  constructor(private _http: HttpClient) {
    this.url = global.url
  }

  getMyComission(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')

    // Hacer peticion ajax
    return this._http.get(this.url + '/get-my-comission/' + id, {
      headers: headers,
    })
  }

  getMyAgentsComissions(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')

    // Hacer peticion ajax
    return this._http.get(this.url + '/get-comission-sales-balance/' + id, {
      headers: headers,
    })
  }

  getMyAgentsComissionsByDateRange(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')

    // Hacer peticion ajax
    return this._http.post(this.url + '/get-comission-sales-balance-by-dates/', params, {
      headers: headers,
    })
  }

  getTotalBalanceOnComissionForOurClient(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')

    // Hacer peticion ajax
    return this._http.post(this.url + '/get-comission-sales-balance-by-client/', params, {
      headers: headers,
    })
  }
}
