import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from "../Config/config";

@Injectable()
export class ShopAddressService {
  public url: string;


  constructor(private _http: HttpClient) {
    this.url = global.url;
   }

  createShopAddress(values): Observable<any>{

    let params = values;
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.post(this.url+'create-shop-address/', params, {headers: headers});
  }
  
  getShopAddresses(val): Observable<any>{

    let id = val;
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.get(this.url+'/get-shop-addresses/'+ id, {headers: headers});
  }

  getShopAddress(val): Observable<any>{

    let id = val;
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.get(this.url+'/get-current-address/'+ id, {headers: headers});
  }

  updateShopAddress(values): Observable<any>{

    let params = values;
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.put(this.url+'/update-point-of-sale', params, {headers: headers});
  }
}