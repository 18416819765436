<h2 mat-dialog-title> Eliminar usuario ❓ </h2>
<mat-dialog-content class="mat-typography">


    <mat-card class="example-card mb-4">
        <mat-card-header>
            <mat-card-subtitle> <span class="material-icons" style="vertical-align: middle;">
                    done_outline
                </span>
                Aquí podras
                <strong>eliminar</strong>
                a tu usuario.
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

            <table class="w-100 table-scroll-wrap animated fadeIn" mat-table [dataSource]="dataSourceUser">
                <ng-container matColumnDef="nombre">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef>Nombe</th>
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div> {{ element.nombre }} </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="apellidos">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef>Apellidos</th>
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>
                            {{ element.apellido_paterno }} {{ element.apellido_materno }}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef>Email</th>
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>
                            {{ element.email }}
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

        </mat-card-content>
    </mat-card>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
    <button mat-raised-button color='accent' (click)="confirmDelete()">Eliminar</button>
</mat-dialog-actions>