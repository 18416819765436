import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
//Get data from the other component
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from '@angular/material/table';

//Services
import { UserService } from "../../../../Services/user.service";

@Component({
  selector: 'remove-helper-modal',
  templateUrl: './removehelper.component.html',
  styleUrls: ['./removehelper.component.css'],
  providers: [ UserService ]
})
export class removehelperComponent implements OnInit {

  userList = [];
  dataSourceUser = new MatTableDataSource<any>(this.userList);
  userData;
  displayedColumns : string[] = ['nombre', 'apellidos','email'];

   constructor(private dialogRef:MatDialogRef<removehelperComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any,
               public _userService : UserService,
               public  router            : Router) { }

   ngOnInit() {
    let id = this.data.user_id;
    //Search user 
    this._userService.getUser(id).subscribe( response => {
     if (response.status == 'success') {
        let params = response.userFound;
        console.log(params);
        this.userList.push(response.userFound);
        this.userData = this.userList;
        this.dataSourceUser = new MatTableDataSource<any>(this.userData);
     }
    });

   }

   //Print document
   printDocument(){
    window.print();
  }

  confirmDelete(){
    let id = this.data.user_id;
    let parent = JSON.parse(localStorage.getItem('parent'));

    this._userService.deleteUser(id).subscribe( response =>{
      if (response.status == 'success') {
        console.log(response);
        this.dialogRef.close();

        window.location.reload();
      }
    })
  }
}  